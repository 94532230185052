<template>
  <div v-if="balance" class="mx-3 mt-7">
    <h2>{{ $t('onboarding.investorApproval.title') }}</h2>
    <p
      class="mt-3"
      v-html="$t('onboarding.investorApproval.desc', {
        cashBalance: $n(balance.cashBalance, 'integerCurrency'),
        minimumDeposit: $n(depositAmount, 'integerCurrency'),
      })"
    />
    <h3>{{ $t('onboarding.investorApproval.subtitle') }}</h3>
    <div>
      {{ $t('onboarding.investorApproval.subDescription') }}
      <ol>
        <li>{{ $t('onboarding.investorApproval.listItemOne') }}</li>
        <li>{{ $t('onboarding.investorApproval.listItemTwo') }}</li>
      </ol>
    </div>
    <ProfileCard />

    <!-- UAT Trigger Minimum Deposit -->
    <div v-if="testEnvironment">
      <br><br><br><br><br>
      <v-card
        align="center"
        color="element"
      >
        <v-card-subtitle>
          {{ $t('onboarding.investorApproval.triggerMinimumDeposit.info') }}
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-btn
              color="primary"
              class="black--text"
              large
              @click="triggerMinimumDeposit"
              :loading="loading"
            >
              {{ $t('onboarding.investorApproval.triggerMinimumDeposit.button') }}
            </v-btn>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </div>
  <p v-else>
    {{ $t('onboarding.investorApproval.error') }}
  </p>
</template>

<script>
import { mapGetters } from 'vuex';
import { triggerMinimumDeposit as trigger } from '@/api/testing';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'MinimumDeposit',
  components: {
    ProfileCard: lazyLoad('components/Shared/Cards/ProfileCard'),
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'balance',
    ]),
    ...mapGetters('application', [
      'configurations',
    ]),
    depositAmount() {
      const amount = this.balance.minimumDeposit - this.balance.cashBalance;
      return amount < 0 ? 0 : amount;
    },
    testEnvironment() {
      return this.configurations?.stage === 'uat';
    },
  },
  methods: {
    async triggerMinimumDeposit() {
      this.loading = true;
      await trigger();
      this.loading = false;
    },
  },
};
</script>
