import apiMakeRequest from '@/plugins/apiRequest/index';
import {
  triggerKycStatusChange as triggerTestKycStatusChange,
  triggerMinimumDeposit as triggerTestMinimumDeposit,
} from '@/graphqlts/mutations';

const triggerKycStatusChange = async () => {
  try {
    const {
      data: {
        triggerKycStatusChange: status,
      },
    } = await apiMakeRequest(triggerTestKycStatusChange);
    return status;
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const triggerMinimumDeposit = async () => {
  try {
    const {
      data: {
        triggerMinimumDeposit: status,
      },
    } = await apiMakeRequest(triggerTestMinimumDeposit);
    return status;
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

export {
  triggerKycStatusChange,
  triggerMinimumDeposit,
};
